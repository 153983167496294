import React from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import "./styles.css"
import Header from "./Header"

const Email = () => {
  return (
    <>
      <Header />

      <Formik
        initialValues={{
          name: "",
          contact: "",
          email: "",
          location: "",
          message: "",
        }}
        onSubmit={(values, actions) => {
          axios({
            method: "post",
            url: "https://www.iconmarichem.com/.netlify/functions/sendEmail",
            headers: { "content-type": "application/json" },
            data: values,
          }).then(result => {
            alert(
              "Thank you for contacting us! Our team will be in touch with you shortly"
            )
            actions.resetForm()
          })
        }}
        validate={values => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          const errors = {}
          if (!values.name) {
            errors.name = "Name Required*"
          }
          if (!values.email || !emailRegex.test(values.email)) {
            errors.email = "Valid Email Required*"
          }
          if (!values.message) {
            errors.message = "Message Required*"
          }
          return errors
        }}
      >
        {() => (
          <div className="row h-75 ">
            <div className="form-wrapper col-12 col-sm-10 col-md-8 col-lg-8 my-lg-auto">
              <Form name="contact">
                <input type="hidden" name="form-name" value="contact" />
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                  />
                  <ErrorMessage name="name" />
                </div>
                <div className="form-group">
                  <label htmlFor="contact">Contact</label>
                  <Field
                    name="contact"
                    type="text"
                    className="form-control"
                    id="contact"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                  />
                  <ErrorMessage name="email" />
                </div>
                <div className="form-group">
                  <label htmlFor="location">Location</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="location"
                    id="location"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <Field
                    className="form-control"
                    component="textarea"
                    name="message"
                    id="message"
                    rows="5"
                  />
                  <ErrorMessage name="message" />
                </div>
                <div className="text-md-right text-center">
                  <button
                    type="submit"
                    className="submit-btn btn btn-sm btn-light text-uppercase text-center mb-5 py-2 pl-3"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
export default Email
