import React from "react"
import { graphql } from "gatsby"
import "../components/styles.css"
import Navbar from "../components/Navbar"
import Sidemenu from "../components/Sidemenu"
import SEO from "../components/seo"
import Header from "../components/Header"
import FooterNew from "../components/FooterNew"
import Form from "../components/Form"

const Contact = ({ data }) => {
  return (
    <>
      <SEO title="Contact" />
      <Header />
      <section className="container-fluid contact position-relative">
        <div className="container">
          <Sidemenu />
          <Navbar />
          <div className="row mt-3 mt-xl-3 mb-lg-3 text-white ">
            <div className="col-12 col-lg-8 col-xl-9  mt-lg-auto  ">
              <h2 className="text-uppercase mb-2 my-lg-0 mb-lg-2 pt-0">
                Contact
              </h2>
              <Form />
            </div>
            <div className=" col-lg-11   col-xl-10">
              <h3 className=" my-2 my-lg-2">Location</h3>

              <div className="row locations">
                <div className="col-md-4 col-lg-3 col-xl-3">
                  <h4 className="mt-1">UNITED STATES - Registered Office</h4>
                  <address>
                    <strong> Icon Maritime Technology & Consulting LLC</strong>
                    <br />
                    Suite #5956, 8 The Green,
                    <br /> Dover, DE 19901 USA
                    <br />
                    <a href="tel:+1617-756-1001">+1-617-756-1001</a>
                    <br />
                    <a href="mailto:contact@iconmarichem.com">
                      contact@iconmarichem.com
                    </a>
                  </address>
                </div>
                <div className="col-md-4 col-lg-3 col-xl-3">
                  <h4 className="mt-1">
                    NORWAY - Technology Center and Supplier Relations
                  </h4>
                  <address>
                    Kanalvegen 8<br /> 4052 Stavanger Norway <br />
                    <a href="tel:+47 950 74 304">+47 950 74 304</a>
                    <br />
                    <a href="mailto:stavanger@iconmarichem.com">
                      stavanger@iconmarichem.com
                    </a>
                  </address>
                </div>
                <div className="col-md-4 col-lg-3 col-xl-3">
                  <h4 className="mt-1">UAE - Strategic Marketing</h4>
                  <address>
                    1011 SIT Tower, <br />
                    Dubai Silicon Oasis Post Box 37944,
                    <br /> Dubai, United Arab Emirates
                    <br />
                    <a href="tel:+971 50 758 0951">+971 50 758 0951 </a>
                    <br />
                    <a href="mailto:dubai@iconmarichem.com">
                      dubai@iconmarichem.com
                    </a>
                  </address>
                </div>
                <div className="col-md-4 col-lg-2 col-xl-3">
                  <h4 className="mt-1">SWITZERLAND - Technology Center</h4>
                  <address>
                    Goethestrasse <br />
                    21 8712 Staefa
                    <br /> Switzerland
                    <br />
                    <a href="tel:+41 (0)767939166">+41 (0)767939166</a>
                    <br />
                    <a href="mailto:zurich@iconmarichem.com">
                      zurich@iconmarichem.com
                    </a>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-footer">
          <FooterNew />
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query MyQuery {
    map: markdownRemark(frontmatter: { title: { eq: "Maps" } }) {
      frontmatter {
        title
      }
      excerpt
      html
    }
  }
`
export default Contact
