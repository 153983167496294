import React from "react"
import Icons from "./Icons"
import "./styles.css"

const FooterNew = () => {
  return (
    <footer className="container py-3">
      <div className="row">
        <div className="d-flex flex-column text-white col-12 col-md-8 pr-5">
          <small className="footer-note">
            All Rights Reserved Icon Maritime Technology &amp; Consulting LLC
          </small>
        </div>
        <Icons className="" />
      </div>
    </footer>
  )
}

export default FooterNew
